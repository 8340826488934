import { Box, styled } from '@mui/material';
import Image, { type StaticImageData } from 'next/image';
import { forwardRef, type ComponentProps } from 'react';

import { ApplicationsLogos } from '@/data/inventory/data';

const _CircleImage = forwardRef<
  HTMLSpanElement,
  {
    alt: string;
    src: StaticImageData;
    contrast?: boolean;
  } & ComponentProps<typeof Box>
>(({ alt, src, contrast, ...props }, ref) => {
  return (
    <Box component={'span'} ref={ref} {...props}>
      <Image
        alt={alt}
        src={src || ApplicationsLogos.Unknown}
        width={24}
        height={24}
        style={{ width: '24px', height: '24px' }}
      />
    </Box>
  );
});

export const CircleImage = styled(_CircleImage)((props) => ({
  width: '24px',
  height: '24px',
  minHeight: '24px',
  minWidth: '24px',
  backgroundColor:
    (props.theme.palette.mode === 'dark' && !props.contrast) ||
    (props.theme.palette.mode !== 'dark' && props.contrast)
      ? '#f5f5f5'
      : 'transparent',
  display: 'inline-flex',
  borderRadius: '50%',
  marginRight: '8px',

  ':last-of-type': {
    marginRight: 0,
  },
}));
