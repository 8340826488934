import { capitalize } from '@mui/material';

import dayjs from '@/utils/dayjs';

export const durationOptions = [
  'hours',
  'days',
  'weeks',
  'months',
  'years',
] as const;
export type DurationFragment = (typeof durationOptions)[number];

export const isDateValid = (date?: unknown): date is Date =>
  date instanceof Date && date.getFullYear() > 2020;

export const addDays = (base: Date, days: number): Date => {
  const result = new Date(base);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMs = (base: Date, ms: number): Date => {
  const result = new Date(base);
  result.setMilliseconds(result.getMilliseconds() + ms);
  return result;
};

/** Test cases
 *
 * fromDaysToBiggestAsPossible(0) // {unit: 'days', value: 0}
 * fromDaysToBiggestAsPossible(365) // {unit: 'years', value: 1}
 * fromDaysToBiggestAsPossible(364) // {unit: 'days', value: 364} - instead of 52 weeks
 * fromDaysToBiggestAsPossible(357) // {unit: 'weeks', value: 51}
 * fromDaysToBiggestAsPossible(330) // {unit: 'months', value: 11}
 * fromDaysToBiggestAsPossible(360) // {unit: 'days', value: 360} - instead of 12 months
 * fromDaysToBiggestAsPossible(720) // {unit: 'days', value: 720}
 * fromDaysToBiggestAsPossible(730) // {unit: 'years', value: 2}
 *
 **/

export function fromDaysToBiggestAsPossible(days: number): {
  unit: DurationFragment;
  value: number;
} {
  if (days === 0) {
    return { unit: 'days', value: 0 };
  }

  const years = Math.floor(days / 365);
  const remainingDays = days % 365;
  if (!remainingDays) {
    return { unit: 'years', value: years };
  }
  const monthsFromDays = Math.floor(remainingDays / 30);
  const remainingDaysFromMonths = remainingDays % 30;
  // checking (monthsFromDays % 12) so it won't return 12 months for 360 days
  if (!remainingDaysFromMonths && monthsFromDays % 12) {
    return { unit: 'months', value: years * 12 + monthsFromDays };
  }

  const weeksFromDays = Math.floor(remainingDays / 7);
  const remainingDaysFromWeeks = remainingDays % 7;
  // checking (weeksFromDays % 52) so it won't return 52 weeks for 364 days
  if (!remainingDaysFromWeeks && weeksFromDays % 52) {
    return { unit: 'weeks', value: years * 52 + weeksFromDays };
  }

  return { unit: 'days', value: days };
}

/** Test cases
 *
 * toDays('days', 0) // 0
 * toDays('days', 364) // 364
 * toDays('days', 365) // 365
 * toDays('weeks', 1) // 7
 * toDays('weeks', 51) // 357
 * toDays('months', 11) // 330
 * toDays('years', 1) // 365
 * toDays('years', 2) // 730
 * toDays('months', 12) // 365
 * toDays('months', 24) // 730
 * toDays('weeks', 52) // 365
 *
 **/

export function toDays(unit: DurationFragment, value: number): number {
  if (value === 0) {
    return 0;
  }

  if (unit === 'years') {
    return value * 365;
  }

  if (unit === 'months') {
    const years = Math.floor(value / 12);
    const remainingMonths = Math.floor(value % 12);
    return years * 365 + remainingMonths * 30;
  }

  if (unit === 'weeks') {
    const years = Math.floor(value / 52);
    const remainingWeeks = Math.floor(value % 52);
    return years * 365 + remainingWeeks * 7;
  }

  if (unit === 'hours') {
    return value * 24;
  }

  return value;
}

export function toFragment(unit: DurationFragment, value: number): number {
  const duration = dayjs.duration({
    days: value,
  });
  return Math.round(duration[`as${capitalize(unit)}` as DurationFragment]());
}
