import { Stack, Typography } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';

import OptionMenu from '@/@core/components/option-menu';
import type { BaseAction } from '@/data/inventory/actions';

interface StatusCardHeaderProps {
  actions: BaseAction[];
  entity: InventoryResource;
  title: string;
  optionMenuTestId?: string;
}

export const StatusCardHeader = (props: StatusCardHeaderProps) => {
  const { actions, entity, title, optionMenuTestId } = props;

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
    >
      <Typography className='font-semibold'>{title}</Typography>
      {!!actions.length && (
        <OptionMenu
          data-testid={optionMenuTestId}
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={actions.map((action) => ({
            text: action.title || action.tooltip,
            menuItemProps: {
              onClick: () => action.execute(entity),
              disabled: action.disabled?.(),
            },
            testId: action.testId,
          }))}
        />
      )}
    </Stack>
  );
};
