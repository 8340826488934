import { Stack, Tooltip, Typography } from '@mui/material';
import type { BackupJob, InventoryResource } from '@repo/api-gw-sdk';
import type { Row } from '@tanstack/react-table';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';

import { isJob } from './properties';

import { isHaveSecurityIssue } from '../security/securityUtils';
import { SkullIcon } from '../security/skullIcon';

interface ResourceNameCellProps {
  row: Row<InventoryResource | BackupJob>;
}

export const ResourceNameCell = ({ row }: ResourceNameCellProps) => {
  const { securityScan } = useFeatureFlags();
  const { isDemo, isDev, demoSettings } = useEnvironment();
  const securityScanEnabled =
    isDemo || isDev ? demoSettings.ransomware : securityScan;

  if (isJob(row.original)) {
    return (
      row.original.jobExecutionDetails.resourceDetails?.resourceName && (
        <Typography>
          {row.original.jobExecutionDetails.resourceDetails.resourceName}
        </Typography>
      )
    );
  }

  if (isHaveSecurityIssue(securityScanEnabled, row.original)) {
    return (
      <Stack alignItems='center' direction='row' gap='8px'>
        <Tooltip
          title='Ransomware indicators were found in some snapshots.'
          PopperProps={{
            sx: {
              width: '240px',
              padding: '8px 12px',
            },
          }}
        >
          <Stack>
            <SkullIcon />
          </Stack>
        </Tooltip>
        <Typography
          color='var(--mui-palette-error-main)'
          sx={{ backgroundColor: 'var(--mui-palette-error-mainOpacity)' }}
        >
          {row.original.resourceName}
        </Typography>
      </Stack>
    );
  }

  return (
    row.original.resourceName && (
      <Typography>{row.original.resourceName}</Typography>
    )
  );
};
