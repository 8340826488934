import {
  type FileSnapshot,
  type InventoryResource,
  SecurityScanConclusionEnum,
  type Snapshot,
} from '@repo/api-gw-sdk';

import { EvidenceTypesEnum } from './securityScanCard';

export const isHaveSecurityIssue = (
  isSecurityScanEnabled: boolean,
  entity: InventoryResource | Snapshot | FileSnapshot
) => {
  return (
    isSecurityScanEnabled &&
    entity.securityScan?.conclusion &&
    entity.securityScan.conclusion !== SecurityScanConclusionEnum.Clean
  );
};

const evidenceTypeLabels: {
  type: EvidenceTypesEnum;
  evidenceLabel: string;
}[] = [
  {
    type: EvidenceTypesEnum.suspicious_extension_detected,
    evidenceLabel: 'Suspicious extensions',
  },
  {
    type: EvidenceTypesEnum.modified_files_became_encrypted,
    evidenceLabel: 'Modified files became encrypted',
  },
  {
    type: EvidenceTypesEnum.suspicious_activity_detected,
    evidenceLabel: 'Suspicious directory activity',
  },
  {
    type: EvidenceTypesEnum.entropy_change_detected,
    evidenceLabel: 'Entropy changes',
  },
  {
    type: EvidenceTypesEnum.encrypted_file_detected,
    evidenceLabel: 'Encrypted file detected',
  },
];

export const getEvidenceTypeLabel = (type: EvidenceTypesEnum) => {
  return evidenceTypeLabels.find((e) => e.type === type);
};
