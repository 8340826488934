import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import ButtonWithIcon from '../buttons/buttonWithIcon';
import LoaderButton from '../buttons/loaderButton';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => Promise<void> | void;
  buttonIconClass?: string;
  buttonTitle: string;
  cancelButtonTitle?: string;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const [error, setError] = useState('');
  const [confirmationInProgress, setConfirmationProgress] = useState(false);

  const onConfirm = () => {
    setError('');
    setConfirmationProgress(true);
    Promise.resolve(props.onConfirm()).catch((error: Error) => {
      setError(error.message);
      throw error;
    });
  };

  // reset error message when dialog is closed
  useEffect(() => {
    if (props.isOpen) {
      setError('');
      setConfirmationProgress(false);
    }
  }, [props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{
        sx: {
          width: '840px',
        },
      }}
    >
      <Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='16px 24px'
          paddingBottom='0'
        >
          <div></div>
          <IconButton onClick={props.onCancel} sx={{ borderRadius: '4px' }}>
            <i
              onClick={props.onCancel}
              className='material-symbols-close-rounded'
            />
          </IconButton>
        </Stack>
        <Stack
          alignItems='center'
          gap='12px'
          sx={{ px: '40px', textAlign: 'center', pb: '40px' }}
        >
          <Typography variant='h2'>{props.title}</Typography>
          <Typography variant='body1' className='mb-[12px]' lineHeight={'20px'}>
            {props.description}
          </Typography>
          <Stack direction='row' gap='12px'>
            <Button onClick={props.onCancel}>
              {props.cancelButtonTitle ?? 'Cancel'}
            </Button>
            {!confirmationInProgress ? (
              <ButtonWithIcon
                onClick={onConfirm}
                icon={props.buttonIconClass}
                text={props.buttonTitle}
                variant='outlined'
              />
            ) : (
              <LoaderButton />
            )}
          </Stack>
        </Stack>
        {error && (
          <Typography padding='0 0 16px 24px' color='error' variant='body1'>
            {error}
          </Typography>
        )}
      </Stack>
    </Dialog>
  );
}
