import { Stack, Tooltip, Typography } from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';

import { useDAL } from '@/data/dal';
import type { BackupVault } from '@/data/vaults/backupVault';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import BackupVaultPopperCard from './BackupPolicyPopperCard';

import { SnapshotIndicator } from '../snapshots/SnapshotIndicator';

export function BackupPolicyTagFromId(props: { policyId: string }) {
  const { policyId } = props;

  const dal = useDAL();
  const { body: policy, isLoading } = dal.backupPolicy.get(policyId);

  if (isLoading || !policy) {
    return null;
  }

  return <BackupPolicyTag policy={policy} />;
}
interface BackupPolicyTagProps {
  policy: BackupPolicy;
}

export default function BackupPolicyTag({ policy }: BackupPolicyTagProps) {
  const { data: vaults, loading: vaultsLoading } = useBackupVaults();

  const distinctVaults = Array.from(
    new Set(policy.schedules.map((schedule) => schedule.vaultId))
  );

  return (
    <>
      <Tooltip
        disableInteractive={false}
        disableHoverListener={vaultsLoading}
        leaveTouchDelay={2000}
        disableFocusListener
        PopperProps={{
          sx: {
            width: '400px',
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'transparent',
              maxWidth: 'none',
            },
          },
        }}
        title={<BackupVaultPopperCard policy={policy} vaults={vaults} />}
      >
        <Stack
          alignSelf='center'
          display='inline-flex'
          alignItems='center'
          direction='row'
          gap='8px'
          sx={{
            border: '1px solid var(--mui-palette-background-dark)',
            borderRadius: '4px',
            padding: '4px 8px',
            margin: '0 4px',
            cursor: 'pointer',
          }}
        >
          {!vaultsLoading &&
            distinctVaults.map((vid, index) => {
              const v = vaults.find((v: BackupVault) => v.id === vid);
              return (
                <SnapshotIndicator
                  key={index}
                  backgroundColor={v?.backgroundColor || null}
                />
              );
            })}
          <Typography variant='body1' sx={{ fontSize: '12px' }}>
            {policy.name}
          </Typography>
        </Stack>
      </Tooltip>
    </>
  );
}
